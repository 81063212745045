.formContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    width: 100%;
}
.mobileInput{
    padding: 8px;
    font-weight: 600;
}
/*.mobileInput:focus{*/
/*    outline: none;*/
/*}*/
/*.mobileInput::-webkit-outer-spin-button,.mobileInput::-webkit-inner-spin-button {*/
/*  -webkit-appearance: none;*/
/*  margin: 0;*/
/*}*/
.mobileInputContainer{
    display: flex;
    border: 2px solid #D9D9D9;
    border-radius: 8px;
    justify-content: space-between;
    padding: 4px;
    font-size: 16px;
}
.verifyOtpBtn{
    border: none;
    background-color: white;
    width: 30%;
    color: #6AC47F;
    font-weight: 700;
}
.verifyOtpBtn:disabled{
    color: grey;
}
.resendText{
    font-size: 11px;
    text-decoration: underline;
    color: black;
}
.lable{
    font-size: 15px;
    font-weight: 600;
    color: #52525C;
}
.nameInput{
    margin-top: 6px;
    padding: 0 10px;
    height: 40px;
    font-weight: 600;
}
.nameInput:focus{
    outline: none;
}
.formItem{
    margin-top: 6px;
}
.formCard{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    text-align: left;
    padding: 20px;
    border: 1px solid black;
    border-radius: 8px;
    padding-bottom: 2rem;
}
.topHeading{
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    margin: 1rem 0 2rem;
}
.btn:disabled {
    background-color: dimgrey;
    color: linen;
  }

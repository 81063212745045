.common-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

body {
    font-family: "Open Sans", sans-serif;
}

.slick-dots li button {
    width: 19px;
    height: 19px;
    background-color: #D9D9D9;
    border-radius: 100%;
    font-size: 0px;
    border: 0;
}

.slick-dots .slick-active button {
    background-color: #FD9822;
}

.slick-dots button::before {
    display: none !important;
}

.slick-dots .slick-active button::before {
    display: none !important;
}

.slick-prev {
    display: none !important;
}
.slick-next {
    display: none !important;
}
.btn:disabled {
    background-color: #B0B0B0!important;
}

@media screen and (max-width: 850px) {
    .slick-dots li button {
        width: 8px;
        height: 8px;
        background-color: #D9D9D9;
        border-radius: 100%;
        font-size: 0px;
        border: 0;
    }
}
